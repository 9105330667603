<template>
  <b-col sm="4">
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
          id="input-group-1"
          label="نام پروژه :"
          label-for="project-name"
        >
          <b-form-input
            id="project-name"
            v-model="form.projectName"
            type="text"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="نوع محصول :" label-for="p-type">
          <b-form-input
            id="p-type"
            v-model="form.pType"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="مقدار :" label-for="p-amount">
          <b-form-input
            id="p-amount"
            type="text"
            v-model="form.amount"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="بودجه :" label-for="p-budget">
          <b-form-input
            id="p-budget"
            type="number"
            min="0"
            v-model="form.budget"
            placeholder="وارد کنید"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary">ارسال درخواست</b-button>
        <b-button type="reset" variant="danger ml-1">پاک کردن</b-button>
      </b-form>
    </b-card>
  </b-col>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BButton,
  BFormGroup,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      form: {
        projectName: "",
        pType: "",
        amount: "",
        budget: 0,
      },
      show: true,
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      var data = JSON.stringify(this.form);
      console.log(data);
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.projectName = "";
      this.form.pType = "";
      this.form.amount = "";
      this.form.budget = 0;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>